
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'App',

  mounted() {
    const noscript = document.querySelectorAll('noscript');
    noscript.forEach((element) => {
      element.remove();
    });
  }
});
